// Convert CommonJS `require` to ES6 `import` if needed. In this case, we're just importing CSS.
import './src/styles/global.css'

// Your existing function, already using ES6 syntax, so it's good to go.
const addScript2 = (url) => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

// Exporting onRouteUpdate using ES6 export syntax.
export const onRouteUpdate = ({ location }) => {
  addScript2('//scripts.iconnode.com/62300.js')
}

// Exporting shouldUpdateScroll using ES6 export syntax.
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  console.log('shouldUpdateScroll called')
  console.log('Navigation action:', location.action)

  setTimeout(() => {
    if (location.action === 'PUSH') {
      console.log('PUSH action detected, scrolling to top')
      window.scrollTo(0, 0)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      console.log(
        'Other action detected, scrolling to saved position:',
        savedPosition
      )
      window.scrollTo(...(savedPosition || [0, 0]))
    }
  }, 10)

  return false
}
